<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('generic.lang_timecardDaily') "
                :icon="icon"
                :subheading="$t('generic.lang_timecardDaily')"
        ></page-title>
        <div class="app-main__inner">
            <daily-time-card-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import DailyTimeCardComponent from "../../../components/timesheet/dailytimecard/DailyTimeCardComponent";

    export default {
        components: {
            DailyTimeCardComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-date icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>